import React from 'react';
import Bowser from 'bowser';

export const isDebuggableEnv = () => {
  const host = window.location.host;
  const isDebuggableEnv =
    host.startsWith('local.') ||
    host.startsWith('localhost') ||
    host.startsWith('127.0.0.1') ||
    host.indexOf('.local') > 0 ||
    host.startsWith('local-');
  return isDebuggableEnv;
};

/**
 * 주어진 객체를 복사해서 새로운 객체를 만든다.
 * @param src
 */
export const deepCopy = (src) => {
  return JSON.parse(JSON.stringify(src));
};

/**
 * 현재 브라우저가 모바일 타입인지를 반환
 */
export const isMobile = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getPlatformType() === 'mobile';
};

/**
 * 주어진 문자열이 빈 값인지를 검사한다.
 * @param value
 * @returns {boolean}
 */
export const isBlank = (value) => {
  if (!value) {
    return true;
  }

  if (typeof value === 'string') {
    const trimmed = value.trim();
    if (trimmed.length === 0) {
      return true;
    }
  } else {
    throw Error(`value ${value}는 string 형식이어야 합니다.`);
  }

  return false;
};

/**
 * value가 빈 값인 경우, defaultValue를 반환한다.
 * @param value
 * @param defaultValue
 * @returns {*}
 */
export const defaultString = (value, defaultValue) => {
  if (isBlank(value)) {
    return defaultValue;
  }
  return value;
};

/**
 * 주어진 문자열에서 숫자만 남긴다.
 */
export const getNumbersOnly = (src) => {
  if (isBlank(src)) {
    return '';
  }
  const numbersOnly = src.replace(/[^\d]/g, '');
  return numbersOnly;
};

/**
 * 주어진 문자열이 빈 값이 아닌지를 검사한다. TODO: lodash 의 것을 사용한다.
 * @param value
 * @returns {boolean}
 */
export const isNotBlank = (value) => {
  return !isBlank(value);
};

/*
 ** 모든 타입 존재여부 체크
 ** undefined, null, string, number, object, array, ""
 */
export const isEmpty = (value) => {
  if (value === null) return true;
  if (typeof value === 'undefined') return true;
  if (typeof value === 'string' && value.trim() === '') return true;
  if (Array.isArray(value) && value.length < 1) return true;
  if (
    typeof value === 'object' &&
    value.constructor.name === 'Object' &&
    Object.keys(value).length < 1 &&
    Object.getOwnPropertyNames(value) < 1
  )
    return true;
  if (typeof value === 'object' && value.constructor.name === 'Object' && Object.keys(value).length < 1) return true;
  if (typeof value === 'object' && value.constructor.name === 'String' && Object.keys(value).length < 1) return true; // new String()
  return false;
};

export const isNotEmpty = (obj) => {
  return !isEmpty(obj);
};

/**
 * 개행문자를 <br/> 태그로 치환.
 */
export const nl2br = (str) => {
  if (isBlank(str)) {
    return <React.Fragment />;
  }
  const splitTags = str.split('\n').map((item, key) => {
    return (
      <React.Fragment key={key}>
        {item}
        <br />
      </React.Fragment>
    );
  });
  return <React.Fragment>{splitTags}</React.Fragment>;
};

/**
 * 두 배열의 내용이 같은지를 체크
 * @param array1
 * @param array2
 * @returns {boolean|boolean|*}
 */
export const isSameArray = (array1, array2) => {
  if (!array1 && !array2) {
    return true;
  }
  if ((array1 && !array2) || (!array1 && array2)) {
    return false;
  }
  if (array1.length !== array2.length) {
    return false;
  }

  // 정렬 상태까지 비교가 필요하면 sort를 하지 말고,
  // 그냥 처음부터 하나씩 비교하다가 다른 값이 있을 경우 return 하는게 가장 빠르다.
  const s1 = [...array1].sort();
  const s2 = [...array2].sort();
  return s1.toString() === s2.toString();

  // return s1.every(function (element, index) {
  //     return element === s2[index];
  // });
};

/**
 * boolean 형식 state 값을 toggle 한다.
 * @param that
 * @param name
 * @returns {(function(): void)|*}
 */
export const toggleState = (that, name) => () => {
  const prevValue = that.state[name];
  that.setState({
    [name]: !prevValue
  });
};

/**
 * state 값을 변경한다.
 * @param that
 * @param name
 * @param value
 * @returns {(function(*))|*}
 */
export const updateState = (that, name, value) => (e) => {
  if (typeof value === 'undefined') {
    value = e.target.value;
  }

  that.setState({
    [name]: value
  });
};

// 한국 전화번호 정규식
const phoneNumberRegExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
/**
 * 전화번호 유효성을 검사한다.
 * @param phoneNumber
 * @returns {boolean}
 */
export const isValidPhoneNumber = (phoneNumber) => {
  return phoneNumberRegExp.test(phoneNumber);
};

/**
 * 카톡 인앱 브라우저에서 창을 닫는다. 동의서/알림장 등에서 사용
 * @returns {boolean}
 */
export const closeKakaoInAppBrowser = () => {
  try {
    window.close();
    window.location.href = 'kakaotalk://inappbrowser/close';
    return false;
  } catch (ignorable) {
    console.log(ignorable);
  }
};

/**
 * 파일 사이즈 계산 (업로드시)
 * @returns {string}
 */
export const returnFileSize = (number) => {
  if (number < 1024) {
    return number + 'bytes';
  } else if (number >= 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + 'KB';
  } else if (number >= 1048576) {
    return (number / 1048576).toFixed(1) + 'MB';
  }
};

export const range = (size, startAt = 0) => {
  return [...Array(size).keys()].map((i) => i + startAt);
};
