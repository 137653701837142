export const DEFAULT_ERROR = {
  title: '처리할 수 없습니다.',
  displayMessage: '이후에 다시 시도하여 주시기 바랍니다.'
};

export const TEEPEE_ERRORS = {
  AUTH_LOGIN_FAILED: {
    title: '로그인을 할 수 없습니다',
    displayMessage: '이이디/비밀번호를 확인해주시기 바랍니다.'
  },
  AUTH_TOKEN_NOT_VALID: {
    title: '로그인을 할 수 없습니다',
    displayMessage: '사용이 중지되었거나 로그인을 할 수 없는 아이디입니다.'
  },
  ALREADY_EXIST_CUSTOMER_WITH_NAME_AND_PHONE: {
    title: '이미 존재하는 고객입니다.',
    displayMessage: '입력하신 이름과 전화번호에 해당하는 고객이 이미 존재합니다.'
  },
  REMAIN_COUNT_INSUFFICIENT: {
    title: '잔여 횟수 부족',
    displayMessage: '잔여 횟수가 부족합니다.'
  },
  REMAIN_MINUTES_INSUFFICIENT: {
    title: '잔여 시간 부족',
    displayMessage: '잔여 시간이 부족합니다.'
  },
  REMAIN_AMOUNT_INSUFFICIENT: {
    title: '잔여 금액 부족',
    displayMessage: '잔여 금액이 부족합니다.'
  },
  EXCEL_PARSE_FAILURE: {
    title: '엑셀 데이터 오류'
  }
};

export const getTpError = (errorResponse) => {
  const response = errorResponse.response;
  let errorTemplate;
  let error;
  if (response && response.data && response.data.resultCode) {
    const apiResult = response.data;
    errorTemplate = TEEPEE_ERRORS[apiResult.resultCode];
    if (errorTemplate) {
      let displayMessage;
      if (typeof errorTemplate.displayMessage === 'function') {
        displayMessage = errorTemplate.displayMessage(apiResult);
      } else {
        displayMessage = errorTemplate.displayMessage;
      }
      error = {
        title: errorTemplate.title,
        displayMessage: displayMessage,
        resultCode: apiResult.resultCode
      };
    }
  }
  if (!error) {
    if (response?.status === 403) {
      error = {
        title: '처리할 수 없습니다.',
        displayMessage: '해당 기능을 수행하는데 필요한 권한이 없습니다',
        resultCode: 'Forbidden'
      };
    }
    // BadRequest 관련, 서버로 부터 전달 받는 resultMessage사용
    else if (response?.status === 400) {
      if (response.data?.error?.name === 'BadRequestException') {
        error = {
          title: '오류',
          displayMessage: '잘못된 요청입니다.',
          resultCode: 'Forbidden'
        };
      } else {
        error = {
          title: response.data?.error?.name,
          displayMessage: response.data?.error?.message,
          resultCode: 'Forbidden'
        };
      }
    }
    // 토큰 및 인증 관련, 서버로 부터 전달 받는 resultMessage사용
    else if (response?.status === 401) {
      error = {
        title: response.data?.error?.name,
        displayMessage: response.data?.error?.message,
        resultCode: response?.status
      };
    } else {
      // TODO: 서버로부터 온 response에 resultMessage가 있은 경우,  있을 경우 고려.
      error = {
        title: DEFAULT_ERROR.title,
        displayMessage: `${
          response?.data?.error?.message ? response.data.error.message : DEFAULT_ERROR.displayMessage
        }`,
        resultCode: 'DEFAULT_ERROR'
      };
    }
  }
  return error;
};
